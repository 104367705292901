.popup {
  display: none;
  align-items: start;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 53px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  height: 100%;
  overflow: scroll;
}

.popupClub,
.popupReviewsGroup {
  align-items: center;
}

.popupContainer {
  max-width: 736px;
  margin: 0 auto;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  padding: 10px;
  position: relative;
  //display: flex;
  justify-content: center;
  align-items: center;
}

.backDrop {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.popupActive {
  display: flex;
}

.popupContainerReviewsGroup {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.popupButtonClose {
  position: absolute;
  top: -25px;
  right: -25px;
}

.popupButtonCloseReviews {
  position: absolute;
  top: -40px;
  right: 0;
  cursor: pointer;
}

.popupHeader {
  max-width: 380px;
  margin-bottom: 24px;
  font-size: 22px;
  line-height: 140%;
}

.popupText {
  font-weight: 300;
  font-size: 16px;
  line-height: 160%;
  margin-bottom: 20px;
}

.popupClose {
  padding: 16px 24px 14px;
  font-size: 16px;
  line-height: 110%;
  text-transform: uppercase;
  border: 1px solid #1cb96e;
  box-sizing: border-box;
  border-radius: 8px;
}

.popupButtonClose {
  cursor: pointer;
}

.popupTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #0d0b1b;
  margin-bottom: 20px;
}

.popupButtonCloseReviews {
  position: absolute;
  top: -40px;
  right: 60px;
  cursor: pointer;
  min-width: 32px;
}

.slider {
  :global(.swiper-button-next:after) {
    position: absolute;
    color: #fff;
    right: 60px;
  }

  :global(.swiper-wrapper) {
    align-items: center;
  }

  :global(.swiper-button-prev:after) {
    position: absolute;
    color: #fff;
    left: 60px;
  }
}

.slider__wrapper {
  overflow: hidden;
  position: relative;
}

.sliderItemImage {
  margin: 0 auto;
  width: 395px;
  // height: 633px;
  object-fit: contain;
}

.slider-counter {
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  text-align: center;
  color: #ffffff;
  position: absolute;
  top: -35px;
  left: 44%;
}

@media (max-width: 1023px) {
  .popupContainer {
    max-width: 343px;
    padding: 10px;
  }

  .popupButtonClose {
    position: absolute;
    top: -25px;
    right: -25px;
  }

  .popupHeader {
    max-width: 220px;
    margin-bottom: 16px;
    font-size: 18px;
  }

  .popupText {
    font-size: 14px;
    margin-bottom: 16px;
  }

  .popupButtonCloseReviews {
    right: 10px;
  }
}

@media (max-width: 768px) {
  .sliderItemImage {
    margin: 0 auto;
    width: 95%;
    height: 95%;
    object-fit: contain;
  }

  .slider {
    :global(.swiper-button-next:after) {
      visibility: hidden;
    }

    :global(.swiper-button-prev:after) {
      visibility: hidden;
    }
  }
}

.photo {
  max-width: 250px;
  max-height: 500px;

  @media (min-width: 375px) {
    max-width: 280px;
  }

  @media (min-width: 1024px) {
    max-width: 500px;
  }
}
