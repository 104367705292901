.container {
  max-width: 1400px;
  padding: 0 14px;
  margin: 0 auto;

  @media (min-width: 1080px) {
    margin: 0 auto;
  }

  @media (min-width: 1550px) {
    max-width: 1300px;
  }
}

.header {
  font-weight: 700;
  font-size: 34px;
  line-height: 120%;
  text-align: center;
  color: #232E23;
  margin-bottom: 12px;

  @media (min-width: 1080px) {
    font-size: 70px;
    line-height: 140%;
    text-align: left;
    margin-left: 200px;
    margin-bottom: 0;
  }
}

.subHeader {
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  color: #999999;
  margin-bottom: 20px;

  @media (min-width: 1080px) {
    font-size: 18px;
    text-align: left;
    margin-left: 200px;
    margin-bottom: 52px;
  }
}

.item {
  border: 2px dashed #BDE3D8;
  border-radius: 16px;
  padding: 26px 20px;
  margin-bottom: 10px;

  @media (min-width: 1080px) {
    border: 4px dashed #BDE3D8;
    border-radius: 30px;
    padding: 48px 200px;
    margin-bottom: 15px;
  }
}

.item:last-child {
  margin-bottom: 0;
}

.blockUser {
  display: grid;
  grid-template-columns: 62px 1fr;
  gap: 16px;
  align-items: center;
  margin-bottom: 12px;

  @media (min-width: 1080px) {
    align-items: start;
    gap: 24px;
  }
}

.blockUser img {
  border-radius: 50%;
}

.name {
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: #000000;

  @media (min-width: 1080px) {
    font-size: 18px;
  }
}

.text {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #181818;
  //margin-bottom: 16px;

  @media (min-width: 1080px) {
    font-size: 18px;
    margin-left: 90px;
    margin-top: -30px;
  }
}

.imageTop {
  margin-top: 16px;
}

.linkSpan {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #4FAF4D;
  cursor: pointer;
}

.none {
  display: none;
}

.date {
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: #999999;

  @media (min-width: 1080px) {
    font-size: 18px;
    line-height: 140%;
    margin-left: 90px;
  }
}

.blockImage {
  display: flex;
  gap: 8px;
  //margin-bottom: 16px;
  flex-wrap: wrap;

  @media (min-width: 1080px) {
    gap: 18px;
    margin-left: 90px;
  }
}

.blockImage span {
  cursor: pointer;
}

.images {
  width: 97px;
  height: 97px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 8px;

  @media (min-width: 1080px) {
    width: 148px;
    height: 148px;
    border-radius: 30px;
  }
}

.blockButton {
  @media (min-width: 1080px) {
    text-align: center;
  }
}

.button {
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #4FAF4D;
  border-radius: 160px;
  width: 100%;
  padding: 17px 0 16px;
  margin-top: 20px;
  cursor: pointer;

  @media (min-width: 1080px) {
    margin-top: 60px;
    font-size: 20px;
    line-height: 130%;
    padding: 22px 58px;
    width: 315px;
  }
}

.containerTwo {
  margin-bottom: 60px;

  @media (min-width: 1080px) {
    margin-bottom: 110px;
  }
}

.containerTop {
  margin-top: 60px;

  @media (min-width: 1080px) {
    margin-top: 110px;
  }
}

.subHeaderTop {
  font-weight: 600;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #73818A;
  margin-bottom: 14px;

  @media (min-width: 1080px) {
    font-size: 24px;
    text-align: left;
    margin-bottom: 12px;
    margin-left: 200px;
  }
}

.headerBottom {
  margin-bottom: 34px;

  @media (min-width: 1080px) {
    margin-bottom: 60px;
  }
}